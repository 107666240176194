import React from "react"

import LayoutBackgroundBurrito from "../components/layout-background-burrito"


const ShopPage = ()  => {
    return (
        <LayoutBackgroundBurrito>
            <h1> Shop </h1>
            <h2> Here will be the shop page to buy shit</h2>
        </LayoutBackgroundBurrito>
    )
}

export default ShopPage